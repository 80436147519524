<template>
  <div class="addDiscount container">
    <el-form :model="form"
             style="min-width: 1000px"
             label-width="100px"
             :rules="rules"
             ref="ruleForm">
      <el-form-item label="排序"
                    prop="categoryId">
        <el-input-number v-model="form.sort"
                         step-strictly
                         :step="1"
                         :min="0"
                         :max="9999999999"
                         :controls="false"
                         placeholder="请输入排序值"></el-input-number>
        <span style="margin-left: 10px; color: #999">数字越大，排名越靠前,如果为空，默认排序方式为创建时间</span>
      </el-form-item>
      <el-form-item label="优惠券名称"
                    prop="coupon_name">
        <el-input v-model="form.coupon_name"
                  placeholder="长度不超过10个字"
                  maxlength="10"
                  disabled></el-input>
      </el-form-item>
      <el-form-item label="使用时间">
        <el-radio v-model="form.time_limit"
                  label="2"
                  disabled>日期</el-radio>
        <el-form-item label-width="0"
                      style="width: 400px; display: inline-block"
                      prop="time">
          <el-date-picker v-model="form.time"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          style="margin-right: 40px"
                          disabled>
          </el-date-picker>
        </el-form-item>
        <el-radio v-model="form.time_limit"
                  label="1"
                  disabled>获得后</el-radio>
        <el-form-item label-width="0"
                      style="display: inline-block"
                      prop="time_days">
          <el-input-number v-model="form.time_days"
                           step-strictly
                           :step="1"
                           :min="1"
                           :max="9999999999"
                           :controls="false"
                           style="width: 100px"
                           disabled></el-input-number>
        </el-form-item>

        天有效
      </el-form-item>
      <el-form-item label="优惠券金额"
                    prop="enough">
        <el-input-number v-model="form.enough"
                         step-strictly
                         :step="0.01"
                         :min="0.01"
                         :max="1000000000000"
                         :controls="false"
                         placeholder="请输入数字"
                         disabled></el-input-number>
        <span>元</span>
      </el-form-item>
      <el-form-item label="优惠门槛"
                    prop="money">
        <el-input-number v-model="form.money"
                         step-strictly
                         :step="0.01"
                         :min="0.01"
                         :max="1000000000000"
                         :controls="false"
                         placeholder="请输入数字"
                         disabled></el-input-number>
        <span>元</span>
      </el-form-item>
      <el-form-item label="退还方式">
        <el-radio v-model="form.return_type"
                  label="1"
                  disabled>不退还</el-radio>
        <el-radio v-model="form.return_type"
                  label="2"
                  disabled>下单取消可退还</el-radio>
      </el-form-item>
      <el-form-item label="发放总数"
                    prop="total">
        <el-input-number v-model="form.total"
                         step-strictly
                         :step="1"
                         :min="-1"
                         :max="1000000000000"
                         :controls="false"
                         placeholder="请输入数字"></el-input-number>
        <span style="margin-left: 10px; color: #999">优惠券总数量，为空时不能领取或发放,-1 为不限制张数</span>
      </el-form-item>
      <el-form-item label="用户领取数"
                    prop="max_get_num">
        <el-input-number v-model="form.max_get_num"
                         step-strictly
                         :step="1"
                         :min="0"
                         :max="1000000000000"
                         :controls="false"
                         placeholder="请输入数字"></el-input-number>
        <span style="margin-left: 10px; color: #999">仅当用户自己领取时，限制的领取数量，手动发放不影响 0为不限制</span>
      </el-form-item>
      <el-form-item label="上架领券中心">
        <el-radio v-model="form.coupon_centre"
                  label="0">否</el-radio>
        <el-radio v-model="form.coupon_centre"
                  label="1">是，可领日期</el-radio>
        <el-form-item label-width="0"
                      style="width: 400px; display: inline-block"
                      prop="show_time">
          <el-date-picker v-model="form.show_time"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          style="margin-right: 40px">
          </el-date-picker>
        </el-form-item>
      </el-form-item>
      <div class="submit flex center align-center">
        <el-button @click="cancel"
                   size="medium">取消</el-button>
        <el-button type="primary"
                   @click="submit"
                   size="medium">提交</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'addDiscount',
  data() {
    let checkTime = (rule, value, callback) => {
      if (value.length == 0 && this.form.time_limit == 2) {
        return callback(new Error('请选择使用时间'));
      }
      callback();
    };
    let checkShowTime = (rule, value, callback) => {
      if (value.length == 0 && this.form.coupon_centre == 1) {
        return callback(new Error('请选择上架时间'));
      }
      callback();
    };
    let checkTimeDay = (rule, value, callback) => {
      if (!value && this.form.time_limit == 1) {
        return callback(new Error('请选择有效天数'));
      }
      callback();
    };
    return {
      coupon_data_id: this.$route.query.coupon_data_id,
      form: {
        coupon_data_id: '',
        sort: undefined,
        coupon_name: '',
        enough: undefined,
        time_limit: '2',
        time: [],
        time_days: undefined,
        show_time: [],
        money: undefined,
        return_type: '1',
        total: undefined,
        max_get_num: undefined,
        coupon_centre: '0'
      },
      rules: {
        coupon_name: [
          { required: true, message: '请输入优惠券名称', trigger: 'blur' }
        ],
        enough: [
          { required: true, message: '请输入优惠券金额', trigger: 'blur' }
        ],
        money: [
          { required: true, message: '请输入优惠券门槛', trigger: 'blur' }
        ],
        time: [{ validator: checkTime, trigger: 'blur' }],
        show_time: [{ validator: checkShowTime, trigger: 'blur' }],
        time_days: [{ validator: checkTimeDay, trigger: 'blur' }]
        // total: [{ required: true, message: '请输入总发放数', trigger: 'blur' }],
        // max_get_num: [
        //   { required: true, message: '请输入用户领取数', trigger: 'blur' }
        // ]
      }
    };
  },
  mounted() {
    this.getCouponInfo();
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    getCouponInfo() {
      let data = {
        coupon_data_id: this.coupon_data_id
      };
      this.axios.post('/store/Shopcoupon/getCouponInfo', data).then((res) => {
        let data = res.data.info;
        let info = {
          coupon_data_id: data.coupon_data_id,
          sort: data.sort || undefined,
          coupon_name: data.coupon_name || '',
          enough: data.enough || undefined,
          time_limit: data.time_limit.toString() || '2',
          time_days: data.time_days || undefined,
          money: data.money || undefined,
          return_type: data.return_type.toString() || '1',
          total: data.total || undefined,
          max_get_num: data.max_get_num,
          coupon_centre: data.coupon_centre == 0 ? '0' : '1'
        };
        // console.log(data.coupon_centre);
        if (data.time_start) {
          info.time = [data.time_start * 1000, data.time_end * 1000];
        } else {
          info.time = [];
        }
        if (data.show_start_time) {
          info.show_time = [
            data.show_start_time * 1000,
            data.show_end_time * 1000
          ];
        } else {
          info.show_time = [];
        }
        this.form = info;
      });
    },
    //提交表单
    submit() {
      if (this.loading) return;
      let check = false;
      this.$refs.ruleForm.validate((valid) => {
        check = valid;
      });
      if (!check) {
        return;
      }
      this.updateCouponTotal();
    },

    updateCouponTotal() {
      let data = {
        coupon_data_id: Number(this.coupon_data_id),
        total: this.form.total,
        sort: this.form.sort || '',
        max_get_num: this.form.max_get_num,
        coupon_centre: this.form.coupon_centre
      };
      if (this.form.coupon_centre == 1) {
        data.show_start_time = this.dayjs(this.form.show_time[0]).format(
          'YYYY-MM-DD HH:mm'
        );
        data.show_end_time = this.dayjs(this.form.show_time[1]).format(
          'YYYY-MM-DD HH:mm'
        );
      } else {
        data.show_start_time = '';
        data.show_end_time = '';
      }
      console.log(data);
      this.axios
        .post('/store/Shopcoupon/updateCouponTotal', data)
        .then((res) => {
          this.$message.success('修改成功');
          this.$router.go(-1);
        });
    }
  }
};
</script>

<style lang='scss' scoped>
.el-input,
.el-input-number,
.el-cascader {
  width: 250px;
  margin-right: 5px;
}

.submit {
  margin: 30px 0;
}
</style>

